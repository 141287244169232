import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GtmService } from './gtm/gtm.service';
import { SessionStorageService } from '../session/session-storage.service';
import { SessionStorageKeyTypes } from '@app/core/enums/session-storage-key-type.enum';
import { UserContext } from '@app/core/dto/user-context-cart.dto';
import { SessionDto } from '@app/core/dto/session.dto';
import { isPlatformBrowser } from '@angular/common';
import { TrackingEvent } from '@app/core/dto/tracking-data.dto';

export enum AnalyticsEvent {
  DATALAYER = 'dataLayer',
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private _eventQueue: EventQueue[] = [];

  private _userContext: UserContext;
  private _userSession: SessionDto;

  private platformId: Object = inject(PLATFORM_ID);
  private readonly _gtmService = inject(GtmService);
  private readonly _router = inject(Router);
  private readonly _sessionStorageService = inject(SessionStorageService);

  private _sendPageView() {
    this.pushTrackingEvent({
      page_location: this._router.url,
      event: TrackingEvent.PAGE_VIEW,
    });
  }

  public init() {
    this._gtmService.inject();
    this._loadQueue();
    this._sendPageView();
  }

  private _loadQueue() {
    if (isPlatformBrowser(this.platformId)) {
      const eventQueue: EventQueue[] = this._sessionStorageService.get(
        SessionStorageKeyTypes.eventQueue,
      );
      this._eventQueue = eventQueue ?? [];
    }
  }

  private pushAndStartQueue(analyticEvent: AnalyticsEvent, event) {
    if (isPlatformBrowser(this.platformId) && event?.event) {
      this._eventQueue.push(
        new EventQueue({
          analyticEvent: analyticEvent,
          eventName: event.event,
          eventType: event.eventType,
          event: event,
        }),
      );
      this._updateStoredQueue();
      this._emitEvent();
    }
  }

  public setUserContext(userContext: UserContext) {
    this._userContext = userContext;
    this._gtmService.setUserContext(userContext);
  }

  public setUserSession(userSession: SessionDto) {
    this._userSession = userSession;
    this._gtmService.setUserSession(userSession);
  }

  private _updateStoredQueue() {
    if (isPlatformBrowser(this.platformId)) {
      this._sessionStorageService.set(
        SessionStorageKeyTypes.eventQueue,
        this._eventQueue,
      );
    }
  }

  private async _emitEvent() {
    if (isPlatformBrowser(this.platformId)) {
      await this._delay(2000);
      let interval = setInterval(() => {
        if (this._eventQueue.length) {
          const event = this._eventQueue?.[0];
          if (
            event?.analyticEvent == AnalyticsEvent.DATALAYER &&
            this._userContext &&
            this._userSession
          ) {
            const status = this._gtmService.sendEvent(
              event.eventName,
              event.event,
            );
            if (status) {
              this._eventQueue.shift();
              if (this._eventQueue.length == 0) {
                this._updateStoredQueue();
                clearInterval(interval);
              }
            }
          }
        } else {
          clearInterval(interval);
        }
      }, 50);
    }
  }

  public pushTrackingEvent(event) {
    if (isPlatformBrowser(this.platformId)) {
      this.pushAndStartQueue(AnalyticsEvent.DATALAYER, event);
    }
  }

  public setPageType(pageType: string) {
    this._gtmService.setPageType(pageType);
  }

  private _delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}

class EventQueue {
  analyticEvent: AnalyticsEvent;
  eventName: string;
  eventType: string;
  event: any;

  constructor(eventData: EventQueue) {
    Object.assign(this, eventData);
  }
}
