import { Injectable, isDevMode } from '@angular/core';

@Injectable()
export class CookieService {
  private readonly _domain = '.industrybuying.com';

  constructor() {}

  getCookie(cname: string) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  // setCookie(name: string, value: any, exDays: number = 0) {  exDays default '0' was used earlier, changed to 1
  setCookie(name: string, value: any, exDays: number = 1) {
    const d = new Date();
    d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);

    let cookie: string = `${name}=${value};expires=${d.toUTCString()};path=/;`;
    if (!isDevMode()) {
      cookie += `domain=${this._domain};`;
    }
    document.cookie = cookie;
  }

  clearCookie(name: string) {
    document.cookie =
      name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}
