// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.

export const environment = {
  production: true,
  staging: false,
  ibAnalyticsUrl: '//a.industrybuying.com',
  gtmAnalyticsKey: 'GTM-W9B2TC2T',
  hostUrl: 'https://www.industrybuying.com',
  apiUrl: 'https://api.industrybuying.com',
  oneLinkURL: 'https://industrybuying.onelink.me/qM9O',
  firebaseConfig: {
    apiKey: 'AIzaSyAlpsJcuwxc4jTje_UaLfpWTET3SyUCseU',
    authDomain: 'industry-buying.firebaseapp.com',
    databaseURL: 'https://industry-buying.firebaseio.com',
    projectId: 'industry-buying',
    storageBucket: 'industry-buying.appspot.com',
    messagingSenderId: '378362668644',
    appId: '1:378362668644:web:977ad56f3b2de8c0',
  },
  sentryDSN: "https://df2e873b6c2f6ce36d47ed3090626747@o281128.ingest.us.sentry.io/4508086902849536",
  appTitle:
    'Buy Industrial and Business Supplies | Industrial Products and Tools Online Shopping | Industry Buying',
  HMAC_SECRET_KEY:
    '1ea9b2a2df59a2e730c85dc22b5ba723d952128fe442a862e1b417ec4506500d',
  RSA_PUBLIC_KEY: `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDF3TieHQy57cr32dwlcNR6aU04
KOETUS7SdXqCRqVvrrH8EhqkTz1KRsFS+F5VTsDzLmqRfgddFt2+9IBKnv6VaNh9
q8W+QZYpZDVSZrGSMqCUUhMU+0AIpEGtQg/BZCAQmhKbDP6f/uwJzjjqiBTNZcZm
6pzDYlzSNmw1Ptuz5wIDAQAB
-----END PUBLIC KEY-----`,
};
