import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SOCIAL_LOGIN_MODE } from '../../enums/login/social-login-mode.enum';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private firebaseApp: any;
  private auth: any;

  private async _loadFirebase() {
    if (!this.firebaseApp) {
      const { initializeApp } = await import('firebase/app');
      this.firebaseApp = initializeApp(environment.firebaseConfig);
    }
  }

  async _getAuth() {
    if (!this.auth) {
      await this._loadFirebase();
      const { getAuth } = await import('firebase/auth');
      this.auth = getAuth(this.firebaseApp);
    }
    return this.auth;
  }

  async login(socialLoginMode: SOCIAL_LOGIN_MODE): Promise<string> {
    if (socialLoginMode == SOCIAL_LOGIN_MODE.GOOGLE) {
      return this._googleAuth();
    }
  }

  private async _googleAuth(): Promise<string> {
    // Create Google authentication provider
    const { GoogleAuthProvider, signInWithPopup } = await import(
      'firebase/auth'
    );

    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(await this._getAuth(), provider);
      // Pass result to the caller
      return result.user['accessToken'];
    } catch (error) {
      throw error;
    }
  }
}
