import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { PLATFORM_ID, inject, isDevMode } from '@angular/core';
import { Response, Request } from 'express';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SeoMetaService } from '@app/core';
import { REQUEST, RESPONSE } from 'src/server/express/express.token';

export const httpInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  const seoMetaService = inject(SeoMetaService);
  const response: Response = inject(RESPONSE, { optional: true });
  const request: Request = inject(REQUEST, { optional: true });
  const platformId: Object = inject(PLATFORM_ID);

  let device = 'DESKTOP';
  let userAgent = request?.headers?.['user-agent'] ?? '';

  let headers: any = {};
  if (isPlatformBrowser(platformId)) {
    userAgent = window.navigator.userAgent;
    headers = req?.headers?.['headers'] ?? {};
  } else {
    headers = request?.headers ?? {};
    headers['Cookie'] = request?.headers?.cookie ?? '';
  }

  if (isMobile(userAgent)) {
    device = 'MOBILE';
  }

  if (isDevMode()) {
    headers = {
      ...headers,
      'is-dev-mode': 'true',
      'dev-sessionid': 'aSRIdnn8NaWesSiLtRt5wnRUhuiu1_C5',
    };
  }

  headers = {
    ...headers,
    'X-REQUESTED-WITH': 'XMLHttpRequest',
    'X-Forward-To': 'node',
    'x-ib-client': device,
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
  };

  const modified = req.clone({
    url: req.url,
    withCredentials: true,
    setHeaders: headers,
  });

  return next(modified).pipe(
    tap({
      next: (event) => {
        if (event instanceof HttpResponse) {
          let apiResponse: any = event;
          if (!req.url?.includes('users/me')) {
            const seoData = apiResponse?.body?.RESPONSE?.pageData?.seoData;
            if (!apiResponse?.body?.RESPONSE?.pageData?.skipSeoTags) {
              seoMetaService.setSeoTags(seoData?.seo);
            }
          }
          if (isPlatformServer(platformId)) {
            apiResponse?.headers?.headers?.forEach(
              (header: string, key: string) => {
                let excludedHeaders = ['content-encoding', 'content-type'];
                if (!excludedHeaders.includes(key)) {
                  response?.setHeader(key, header);
                }
              },
            );
          }

          if ([200, 201, 202].includes(apiResponse.status)) {
            if ([301, 302].includes(apiResponse?.body?.STATUS_CODE)) {
              const redirectUrl =
                apiResponse?.body?.RESPONSE?.redirectionUrl ?? '/';
              const redirectCode = Number(apiResponse?.body?.STATUS_CODE);
              response?.redirect(redirectCode, redirectUrl);
            }
          }
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse) {
          if ([301, 302].includes(err.status)) {
            const redirectUrl = err.error.redirect;
            const redirectCode = Number(err.error.status_code);
            response?.redirect(redirectCode, redirectUrl);
          }
          return;
        }
      },
      complete: () => {
        if(isDevMode()){
          console.log('Request completed');
        }
      },
    }),
  );
};

const isMobile = (userAgent: string) => {
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(userAgent);
};
