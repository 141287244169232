import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root',
})
export class WidgetsService {
  cssClassMap: Map<number, String> = new Map();
  cssStylesMap: Map<number, any> = new Map();
  inputData: Map<String, any> = new Map();

  private inputValueSubject = new BehaviorSubject<any>('');
  inputValue$ = this.inputValueSubject.asObservable();

  setInputValue(value: string, inputName: string) {
    if (inputName) {
      this.inputData.set(inputName, value);
    }

    this.inputValueSubject.next(Object.fromEntries(this.inputData));
  }
}
