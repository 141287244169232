import { DOCUMENT, PlatformLocation } from '@angular/common';
import { Injectable, RendererFactory2, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '@env/environment';

class SeoTag {
  tag: SeoTagType;
  attributeName: SeoAttributeName;
  attributeKey: string;
  attributeValue: string;
  path?: string;
  incorrectUrl?: boolean;
  overrideQueryParams?: string;
}

enum SeoTagType {
  META = 'meta',
  LINK = 'link',
  SCRIPT = 'script',
}

enum SeoAttributeName {
  NAME = 'name',
  HTTP_EQUIV = 'http-equiv',
  PROPERTY = 'property',
  TYPE = 'type',
}
class SeoDto {
  alexaVerifyId?: SeoTag;
  androidAppURI?: SeoTag;
  description?: SeoTag;
  googleSiteVerification?: SeoTag;
  keywords?: SeoTag;
  noIndexSeo?: SeoTag;
  redirectUrl?: SeoTag;
  seoFooterTitle?: SeoTag;
  title?: SeoTag;
  ogTitle?: SeoTag;
  ogDescription?: SeoTag;
  ogImage?: SeoTag;
  ogUrl?: SeoTag;
  ogSiteName?: SeoTag;
  ogType?: SeoTag;
  twitterCard?: SeoTag;
  twitterTitle?: SeoTag;
  twitterDescription?: SeoTag;
  twitterImage?: SeoTag;
  revisitAfter: SeoTag;
  rating: SeoTag;
  resourceType: SeoTag;
  copyright: SeoTag;
  distribution: SeoTag;
  contentLanguage: SeoTag;
  author: SeoTag;
  createdBy: SeoTag;
  contentType: SeoTag;
  canonical: SeoTag;
}

@Injectable()
export class SeoMetaService {
  private _rendererFactory = inject(RendererFactory2);
  private _document: Document = inject(DOCUMENT);
  private _titleService: Title = inject(Title);
  private _metaService: Meta = inject(Meta);
  private _platformLocation = inject(PlatformLocation);

  setSeoTags(seoData?: SeoDto) {
    this._removeExistingTags();
    this._createCanonicalURL();
    this._insertTags(seoData);
  }

  private _insertTags(seoTags?: SeoDto) {
    const tagId = 'dynamic';
    const appTitle = seoTags?.title?.attributeValue || environment.appTitle;
    this._titleService.setTitle(appTitle);

    for (const key in seoTags) {
      if (seoTags[key]?.attributeValue !== null) {
        if (seoTags[key].tag === SeoTagType.META) {
          this._metaService.addTag({
            [seoTags[key].attributeName]: seoTags[key].attributeKey,
            id: tagId,
            content: seoTags[key].attributeValue,
          });
        } else if (seoTags[key].tag === SeoTagType.SCRIPT) {
          seoTags[key].attributeValue.forEach((value) => {
            const renderer = this._rendererFactory.createRenderer(null, null);
            const script: HTMLScriptElement = renderer.createElement('script');
            script.id = tagId;
            script[seoTags[key].attributeName] = seoTags[key].attributeKey;
            script.text = JSON.stringify(value);
            renderer.appendChild(this._document.head, script);
          });
        }
      }
    }
  }

  private _removeExistingTags() {
    const existingTags = this._document.querySelectorAll('#dynamic');
    if (existingTags) {
      existingTags?.forEach((tag) => {
        tag.remove();
      });
    }
  }

  private _createCanonicalURL() {
    const tagId = 'canonical';
    const existingTag = this._document.getElementById(tagId);
    if (existingTag) {
      existingTag.remove();
    }
    let pathName = this._platformLocation.pathname;
    if (pathName === '/') {
      pathName = '';
    }
    const canonicalUrl = environment.hostUrl + pathName;
    const renderer = this._rendererFactory.createRenderer(null, null);
    let link: HTMLLinkElement = renderer.createElement('link');
    link.id = tagId;
    link.rel = 'canonical';
    link.href = canonicalUrl;
    renderer.appendChild(this._document.head, link);
  }
}
