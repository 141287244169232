export class TrackingItemsDto {
  itemId: string;
  itemName: string;
  affiliation?: string;
  coupon?: string;
  discount: number;
  index?: number;
  itemBrand: string;
  itemCategory: string;
  itemCategory2: string;
  itemCategory3: string;
  itemCategory4: string;
  itemCategory5: string;
  itemListId?: string;
  itemListName: string;
  itemVariant: string;
  locationId: string;
  promotionId: string;
  promotionName: string;
  price: number;
  quantity: number;
}

export class TrackingDto {
  event: string;
  eventType: string;
  currency: string;
  value: number;
  coupon?: string;
  paymentType?: string;
  transactionId?: string;
  shipping?: number;
  tax?: number;
  items: TrackingItemsDto[];
}

export enum TrackingEvent {
  PAGE_VIEW = 'page_view',

  CLICK = 'click',

  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  SELECT_ITEM = 'select_item',
  ADD_PAYMENT_INFO = 'add_payment_info',
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  SELECT_PROMOTION = 'select_promotion',
  VIEW_CART = 'view_cart',
  VIEW_ITEM = 'view_item',
  PURCHASE = 'purchase',
  SEARCH = 'search',

  LOGIN_INITIATED = 'login_initiated',
  LOGIN = 'login',
  LOGIN_FAILED = 'login_failed',

  SIGNUP_INITIATED = 'signup_initiated',
  SIGNUP = 'sign_up',
  SIGNUP_FAILED = 'signup_failed',

  IBCREDIT_GST = 'ibcredit_gst',
  IBCREDIT_EMAIL = 'ibcredit_email',
  IBCREDIT_MOBILE = 'ibcredit_mobile',
  IBCREDIT_LEAD = 'lead_ibcredit',
}
