import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  set(key: string, data: any): void {
    if (isPlatformBrowser(this.platformId)) {
      let dataToSetInStorage = data;

      if (data && typeof data === 'object') {
        dataToSetInStorage = JSON.stringify(data);
      }

      sessionStorage.setItem(key, dataToSetInStorage);
    }
  }

  get(key: string): any {
    let d: any = null;

    if (isPlatformBrowser(this.platformId)) {
      const dataFromSessionStorage = sessionStorage.getItem(key);

      try {
        d = JSON.parse(dataFromSessionStorage);
      } catch (error) {
        d = dataFromSessionStorage;
      }
    }

    return d;
  }
}
