import {
  HttpEvent,
  HttpRequest,
  HttpInterceptorFn,
  HttpHandlerFn,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import * as env from 'src/environments/env.json';
import { StringUtils } from '@app/core/utils/string.utils';

export const apiPrefixInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  let apiUrl = environment.apiUrl;
  if (environment.staging) {
    const subdomain = env.subdomain;
    apiUrl = StringUtils.format(apiUrl, subdomain);
  }
  if (!/^(http|https):/i.test(req.url)) {
    req = req.clone({ url: apiUrl + req.url });
  }

  return next(req);
};
